.welcome-note {
	background-size: contain;
	padding: $spacing*2 0px;
	background-position: 130px center;
	background-repeat: no-repeat;
	@media (max-width: $mobile) {
		background-image: none !important;
	}
	&.container {
		margin-top: 40px;
		margin-bottom: 40px;
		padding: 0;
	}
	h1 {
		margin: 0;
		color: $color1;
		font-weight: 700;
		font-size: 48px;
		line-height: 56px;
		margin-bottom: $spacing;
	}
	p {
		margin: 0;
		color: $color1;
		font-weight: 400;
		font-size: 24px;
		line-height: 32px;
		margin-bottom: $spacing*1.5;
	}
	@media (max-width: $large-screen) {
		//background-position: right;
		background-position: 90px center;
	}
	@media (max-width: $mobile) {
		background-position: top center;
		h1 {
			font-size: 38px;
			line-height: 46px;
		}
		p {
			font-size: 22px;
			line-height: 28px;
		}
	}
}


.usp-bar {
	padding: 0;
	background-color: $color4;
	text-align: center;
	@media (max-width: $mobile) {
		.col {
			flex-basis: 33.3333%;
		}
	}
	span {
		font-size: 14px;
		line-height: 26px;
		color: #000;
		font-weight: 800;
		text-transform: uppercase;
	}
	i {
    font-size: 28px;
    padding-right: 5px;
    vertical-align: middle;
    color: #000;
    @media (max-width: $mobile) {
    	display: block;
    	margin: auto;
    	padding: 0 0 5px 0;
    }
	}
}

.range-selector {
	.col-6.grey{
		background-color: #eee;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 100%;
			bottom: 0;
			left: -50vw;
			background-color: #eee;
		}
		&.right {
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 100%;
				bottom: 0;
				right: -50vw;
				background-color: #eee;
			}
		}
		img {
			width: 100%;
		}
	}
	a {
		color: inherit;
		&:hover, &:focus {
			.btn {
				background-color: rgba(#e6007d, 0.9);
				width: 175px;
			}
		}
	}
	.range {
		padding: 40px;
		text-align: center;
		display: flex;
    flex-direction: column;
    height: 100%;
		img {
			padding: 0 $spacing;
			width: 100%;
		}
		h2 {
			margin: $spacing 0 0 0;
			font-size: 22px;
			font-weight: 400;
			text-transform: uppercase;
		}
		p {
			font-size: 16px;
			line-height: 22px;
			font-weight: 400;
			padding: 0 $spacing;
			margin: $spacing/2 0;
			flex-grow: 1;
		}
		.btn {
			display: inline-block;
	    width: 150px;
	    padding: 10px;
	    font-size: 16px;
	    margin: auto;
		}
	}
	@media (max-width: $small-screen) {
		.col-4 {
			flex-basis: 50%;
		}
	}
	@media (max-width: 570px) {
		.col-4 {
			flex-basis: 100%;
		}
	}
}

.how-it-works {
	padding: $spacing*2 0px;
	background-size: cover;
	background-position: center;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(#ffc935, 0.9);
	}
	h3 {
		text-align: center;
		margin: 0;
		font-weight: 400;
		text-transform: uppercase;
		font-size: 26px;
		line-height: 30px;
		margin: $spacing 0px;
	}
	.col-3 {
		@media (max-width: 949px) {
			flex-basis: 50%;
		}
		@media (max-width: 497px) {
			flex-basis: 50%;
			padding: 15px;
		}

	}
	.how-point {
		.circle {
		  position: relative;
		  display: block;
		  margin: 2em 0;
		  background-color: transparent;
		  color: #000;
		  text-align: center;
		  text-transform: uppercase;
		  font-size: 22px;
		  line-height: 36px;
		  @media (max-width: $large-screen) {
		  	font-size: 18px;
		  	line-height: 28px;
		  }
		  @media (max-width: 666px) {
		  	font-size: 14px;
		  	line-height: 24px;
		  }
		  &:after {
			  display: block;
			  padding-bottom: 100%;
			  width: 100%;
			  height: 0;
			  border-radius: 50%;
			  background-color: #ffc935;
			  border: 5px solid #fff;
			  content: "";
			}
			.number {
				position: absolute;
				top: 5px;
				left: 5px;
				height: 60px;
				width: 60px;
				background-color: #f9b700;
				border-radius: 100px;
				line-height: 50px;
				border: 4px solid #fff;
				font-size: 20px;
				color: #000;
				@media (max-width: $large-screen) {
					font-size: 16px;
					height: 45px;
					width: 45px;
					line-height: 38px;
				}
				@media (max-width: 949px) {
					font-size: 20px;
					height: 60px;
					width: 60px;
					line-height: 50px;
					top: 20px;
					left: 20px;
				}
				@media (max-width: 666px) {
					font-size: 16px;
					height: 45px;
					width: 45px;
					line-height: 38px;
				}
				@media (max-width: 497px) {
					font-size: 14px;
					height: 35px;
					width: 35px;
					line-height: 26px;
					top: -3px;
				}
			}
			.circle__inner {
			  position: absolute;
			  top: 0;
			  bottom: 0;
			  left: 0;
			  width: 100%;
			  height: 100%;
			}
			.circle__wrapper {
			  display: table;
			  width: 100%;
			  height: 100%;
			}
			.circle__content {
			  display: table-cell;
			  padding: 1em;
			  vertical-align: middle;
			  @media (max-width: 497px) {
			  	padding: 1.5em;
			  	font-weight: 700;
			  }
			}
		}
	}
}

.testimonials {
	padding: $spacing*2 0px 50px 0;
	.slick-arrow {
		top: 50%;
		position: absolute;
		transform: translate(0%, -50%);
		height: 30px;
		width: 30px;
		border: none;
		outline: none;
		background-color: transparent;
		color: transparent;
		z-index: 9999;
		cursor: pointer;
		padding: 0;
	}
	.slick-prev {
		left: -40px;
		@media (max-width: $mobile) {
			left: -15px;
		}
		background-image: url('../images/chevron-circle-left.svg');
		&:hover, &:focus {
			background: inherit;
			background-image: url('../images/chevron-circle-left.svg');
		}
	}
	.slick-next {
		right: -40px;
		@media (max-width: $mobile) {
			right: -15px;
		}
		background-image: url('../images/chevron-circle-right.svg');
		&:hover, &:focus {
			background: inherit;
			background-image: url('../images/chevron-circle-right.svg');
		}
	}

	.testimonial-holder {
		padding: 0px 20px;
		.testimonial {
			background-color: #eeeeee;
			padding: $spacing*2 $spacing;
			border-radius: 30px;
			position: relative;
			margin-bottom: 20px;
			.quote {
				position: absolute;
		    top: 30px;
		    left: 50%;
		    transform: translate(-50%, 0%);
		    height: 45px;
		    z-index: 999;
			}
			.star {
				position: absolute;
		    bottom: 5px;
		    left: 0;
		    right: 0;
		    height: 30px;
		    width: 100%;
		    z-index: 999;
			}
			p:first-of-type {
				margin: 60px 0 10px 0;
			}
			p {
				margin: 0 0 10px 0;
				text-align: center;
				font-size: 16px;
				line-height: 24px;
			}
		}
	}
}




.hero-bar{
	margin: $spacing/2 0px;
	.hero{
		position: relative;
		transition: all 0.3s;
		@media (max-width: $mobile) {
			height: 200px;
		}
		img{
			@media (max-width: $mobile) {
				height: 100%;
				object-fit:cover;
			}
			width: 100%;
			height: auto;
			display: block;
		}
		&-overlay{
			position: absolute;
			top: 50%;
			width: 100%;
			text-align: center;
			transform: translate(0%, -50%);
			font-weight: 400;
			z-index: 9;
			color: #fff;
			font-family: $font1;
			font-size: 22px;
			text-transform: uppercase;
			span{
				font-weight: 700;
				color: #fff;
			}
		}
		&-subtitle{
			position: absolute;
			top: 65%;
			width: 80%;
			left: 50%;
			text-align: center;
			transform: translate(-50%, -50%);
			font-weight: 400;
			z-index: 9;
			color: #fff;
			font-family: $font1;
			font-size: 16px;
			text-transform: uppercase;
			opacity: 0;
			transition: all 0.3s;
		}
		&:after{
			content: '';
			position: absolute;
			top: 50%;
			width: 100%;
			height: 60px;
			transform: translate(0%, -50%);
			opacity: 0.8;
			transition: all 0.5s;
		}
		&:hover{
			&:after{
				height: 100%;
			}
			.hero-subtitle{
				opacity: 1;
			}
		}
	}
	.hero.color1{
		&:after{
			background-color: $color1;
		}
	}
	.hero.black{
		&:after{
			background-color: #000;
		}
	}
	.hero.color3{
		&:after{
			background-color: $color3;
		}
	}
}








.instagram-holder{
	position: relative;
	// background-color: $color3;
  background-color: #eee;
	padding: $spacing 0px;
	.instagram-link{
		color: #333;
		line-height: 40px;
		position: relative;
		font-size: 22px;
		text-transform: uppercase;
		font-family: $font1;
		font-weight: 400;
		margin-left: 45px;
		&:after{
			content: '';
			position: absolute;
			background-image: url('../images/social/instagram.svg');
			left: -40px;
			height: 35px;
			width: 35px;
			top: -4px;
			background-repeat: no-repeat;
		}
	}
}

.banner{
	background-color: $color4;
	padding: $spacing*2 0px;
	position: relative;
	overflow: hidden;
	.col{
		padding-top: 0px;
		padding-bottom: 0px;
		text-align: center;
		@media (max-width: $mobile) {
			margin: 20px;
		}
	}
	@media (max-width: $mobile) {
		.col-6:first-child{
			padding-bottom: 40px;
			ul{
				margin-left: 60px;
			}
		}
		.col-6{
			ul li{
				font-size: 18px;
				line-height: 26px;
			}
			img{
				height: 52px;
				width: auto;
			}
		}
	}
	&-icon{
		height: 70px;
	}
	ul{
		margin: 0px;
		padding: 0px;
		list-style-type: none;
		line-height: 35px;
		margin: 0;
		li{
			font-size: 24px;
			span{
				font-weight: 700;
			}
		}
	}
	@media (max-width: $mobile) {
		&:after{
			display: none;
		}
	}
}

.reviews{
	position: relative;
  margin-bottom: -80px;
}

.title{
	text-transform: uppercase;
	font-size: 24px;
	margin-bottom: 5px;
	font-weight: 400;
	margin-top: 0px;
	span{
		font-weight: 700;
	}
}
.subtitle{
	text-transform: uppercase;
	font-size: 14px;
	margin-bottom: 10px;
	font-weight: 400;
	margin-top: 0px;

	&2 {
		text-transform: none;
		font-size: 13px;
		margin-bottom: 10px;
		font-weight: 400;
		margin-top: 0px;
		color: #666;
	}

	a {
		color: inherit;
	}
}


.clothing-section{
	padding: $spacing*2 0;
	background-color: #fff;
	a{
		color: inherit;
		outline: none;
		&:hover{
			.clothing-btn{
				filter: brightness(85%);
			}
		}
	}
	.clothing-item{
		text-align: center;
		img{
			width: 100%;
		}
		&-title{
			text-transform: uppercase;
			font-size: 16px;
			font-family: $font1;
		}
		.clothing-btn{
			background-color: $color4;
			display: inline-block;
			padding: 8px 16px;
			margin-top: 15px;
			color: #fff;
			transition: all 0.3s;
			border-radius: 100px;
		}
	}
	&-viewall{
		width: 100%;
		text-align: center;
		font-size: 16px;
		margin: 30px 0 0 0;
		text-transform: uppercase;
		a{
			font-family: $font1;
		}
	}
}
.clothing-section.grey{
	background-color: $offwhite;
}

.content{
	padding: $spacing*2 0;

}


