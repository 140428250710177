.cookie-notice {
  background: $cookie-background;
  bottom: 0;
  color: $cookie-font-color;
  font-size: .875em;
  left: 0;
  line-height: 1.5;
  padding: .5em 5%;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 20000000; 
}

.cookie-notice .cookie-notice__accept,
.cookie-notice .cookie-notice__accept:hover {
  background: $cookie-button;
  border-radius: .1875em;
  color: #FFFFFF;
  cursor: pointer;
  font-size: .7em;
  font-weight: 700;
  padding: .5em .75em;
  text-decoration: none; 
}
