.footer {
	position: relative;
	&:after {
	  background-image: url('../images/color-border.svg');
	  position: absolute;
	  content: '';
	  height: 6px;
	  right: 0;
	  left: 0; 
	  top: 0;
	  background-size: cover;
		background-repeat: no-repeat;
	  // @media (max-width: $mobile) {
	  // 	left: -20px;
	  // 	right: -20px;
	  // }
	}
	.footer-body {
		padding: $spacing*2 0;
		background-color: #EEEEEE;
		.footer-left {
			float: left;
			ul{
				margin: 0px;
				padding: 0px;
				list-style-type: none;
				li{
					padding: 5px 0px;
					font-size: 14px;
					font-family: $font2;
					a{
						color: inherit;
					}
				}
				&:nth-child(2) {
					margin-top: 20px;
				}
			}
		}
		.footer-right {
			float: right;
			text-align: right;
			width: 50%;
			@media (max-width: $mobile) {
				float: left;
				text-align: left;
				width: 100%;
			}
			ul{
				margin: 0px;
				padding: 0px;
				list-style-type: none;
				li{
					padding: 5px 0px;
					font-size: 14px;
					font-family: $font2;
					a{
						color: inherit;
					}
				}
				&:nth-child(2){
					margin-top: 20px;
				}
				&:nth-child(1){
					li a{
						line-height: 30px;
					}
					img{
						height: 30px;
						margin-left: 10px;
						float: right;
					}
				}
			}
			.social-links{
				li{
					display: inline-block;
					padding: 0px 10px;
					&:last-child{
						padding-right: 0px;
					}
					img{
						height: 35px;
					}
				}
			}
		}
	}
}

