.subpage {
  .title {
    background-color: #f9b700;
    padding: 40px;
    h1 {
      margin: 0;
      color: #fff;
      text-transform: uppercase;
      font-weight: 800;
    }
  }
}