.image-slider{
	div{
		height: 600px;
		img{
			width: 100%;
			height: 600px;
			object-fit: cover;
			object-position: center;
		}
	}
}
.clothing-slider, .product-slider, .product-slider-nav{
	visibility: hidden;
}
.slick-initialized{
	visibility: visible;
}

.slick-dots{
	margin: 20px 0 0 0;
	padding: 0px;
	list-style-type: none;
	text-align: center;
	li{
		display: inline-block;
		padding: 5px;
		color: transparent;
		button{
			color: transparent;
			height: 12px;
			width: 12px;
			border-radius: 10px;
			padding: 0px;
			margin: 0px;
			border: none;
			background-color: #CCCCCC;
			outline: none;
			cursor: pointer;
		}
	}
	.slick-active{
		button{
			background-color: #AAAAAA;
		}
	}
}

.product-slider.recycled-range{
	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100px;
		height: 35px;
		background-image: url('../images/repreve.png');
		background-size: contain;
		background-repeat: no-repeat;
		z-index: 100;
	}
}

.product-slider{
	&:after{
		content: '';
		position: absolute;
		bottom: 30px;
		right: 60px;
		background-image: url('../images/icons/plus.svg');
		height: 35px;
		width: 35px;
		pointer-events: none;

    @media (max-width: $mobile) {
    	display: none;
    }
	}
	div{
		padding: 0px 10px;
		outline: none;
		img{
			width: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
}
.product-slider-nav{
	div{
		height: 80px;
		outline: none;
		cursor: pointer;
		img{
			margin: auto;
			width: auto;
			height: 80px;
			object-fit: cover;
			object-position: center;
		}
	}
}
.zoomImg{
	background-color: #fff;
}


.slick-track {
	display: flex !important;
}

.slick-slide {
	height: inherit !important;
	div {
		height: 100%;
	}
}


