.nav{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background-color: #fff;
	padding: $spacing 0 0 0;
	z-index: 99999;
	@media (max-width: $mobile) {
		padding: 0px;
	}
	.mobile-nav-burger{
		cursor: pointer;
		@media (max-width: $mobile) {
			display: block;
			bottom: 38px;
		}
		display: none;
		position: absolute;
		bottom: 18px;
		right: 20px;
		img{
			height: 30px;
		}
	}
	.title{
		font-family: $font1 !important;
	}
	box-shadow: 1px 4px 17px -10px rgba(0,0,0,0.75);
	&:after {
	  background-image: url('../images/color-border.svg');
	  position: absolute;
	  content: '';
	  height: 6px;
	  right: 0;
	  left: 0;
	  bottom: 0;
	  background-size: cover;
	  background-repeat: no-repeat;
	  @media (max-width: $mobile) {
	  	left: -20px;
	  	right: -20px;
	  }
	}
	.container{
		position: relative;
		z-index: 999;
		@media (max-width: $mobile) {
			padding: 0;
		}
	}
	.logo{
		height: 80px;
		margin-bottom: $spacing;
		@media (max-width: $mobile) {
			height: 70px;
			margin-top: 30px;
			margin-left: 20px;
		}
	}
	.contact-links{
		margin: 0px;
		padding: 0px;
		list-style-type: none;
		position: absolute;
		right: 20px;
		top: 0px;
		@media (max-width: $mobile) {
			position: relative;
			right: auto;
			left: auto;
		}
		li {
			display: inline-block;
			padding: 8px 12px;
			border-radius: 100px;
			font-size: 14px;
			transition: all 0.3s;
			margin-left: 10px;
			span {
				@media (max-width: $mobile) {
					display: none;
				}
			}
			&:hover, &:focus {
				filter: brightness(80%);
			}
			&:first-child {
				background-color: $color4;
			}
			&:nth-child(2) {
				background-color: $color3;
			}
			&:nth-child(3) {
				background-color: $color1;
			}
			i {
				margin-right: 6px;
				@media (max-width: $mobile) {
					margin: 0;
					font-size: 22px;
				}
			}
			a {
				color: #fff;
			}
		}
		@media (max-width: $mobile) {
			left: 0;
			right: 0;
			display: flex;
			li {
				flex-basis: 50%;
				text-align: center;
				border-radius: 0;
				margin: 0 !important;
			}
		}
	}
	&-links{
		@media (max-width: $mobile) {
			display: none;
		}
		margin: 0px;
		padding: 0px;
		list-style-type: none;
		position: absolute;
		right: 20px;
		bottom: 10px;
		text-align: right;
		li{
			display: inline-block;
			padding: $spacing 5px;
			z-index: 9;
			a {
				font-family: $font1;
				color: #000;
				font-size: 14px;
				@media (max-width: 815px) {
					font-size: 14px;
				}
			}
			&:last-child{
				padding-right: 0px;
			}
		}
		.dropdown{
			cursor: pointer;
		}
	}
}
.nav.open{
	height: 440px;
}

.dropdown-menu{
	text-align: left;
	background-color: #EEEEEE;
	display: none;
	ul{
		padding: 0px;
		margin: 0px;
		position: relative;
		transition: all 0.3s;
		a{
			color: #000;
		}
		.title{
			margin-bottom: 20px;
		}
		li{
			display: block;
			padding: 0 0 20px 0;
		}
	}
}

.mobile-nav{
	position: fixed;
	top: 0;
	bottom: 0;
	left: -100vw;
	width: 100vw;
	z-index: 9999999999;
	background-color: $color3;
	transition: all 0.3s;
	opacity: 0;
	ul{
		margin: 20px 0;
		padding: 0px;
		list-style-type: none;
		width: 100%;
		text-align: center;
		li{
			padding: 10px 0px;
			a{
				color: inherit;
				font-size: 18px;
				font-family: $font1;
			}
		}
	}
	.nav-close{
		img{
			height: 30px;
		}
	}
}
.mobile-nav.open{
	left: 0;
	opacity: 1;
}
