html{
	font-family: $font1 !important;
}
h1,h2,h3,h4,h5,h6{
	font-family: $font1;
	font-weight: 700 !important;
}
h1,.h1 { font-size: 32px; }
h2,.h2 { font-size: 28px; }
h3,.h3 { font-size: 24px; }
h4,.h4 { font-size: 20px; }
h5,.h5 { font-size: 18px; }
h6,.h6 { font-size: 16px; }

.content, .text-section {
	h1,h2,h3,h4,h5,h6{
		font-family: $font1;
		font-weight: 400 !important;
		margin-top: 0;
		margin-bottom: 10px;
		color: #000 !important;
		padding: 0;
	}
	h1,.h1 { font-size: 24px; }
	h2,.h2 { font-size: 20px; }
	h3,.h3 { font-size: 18px; }
	h4,.h4 { font-size: 16px; }
	h5,.h5 { font-size: 14px; }
	h6,.h6 { font-size: 12px; }
}

a, button{
	font-family: $font2;
	text-decoration: none;
}
input{
	font-family: $font2;
}
p{
	font-family: $font2;
	font-size: 16px;
	line-height: 26px;
}
p{
	a{
		color: inherit;
		text-decoration: underline;
	}
}

.features{
	font-family: $font2;
	font-size: 16px;
	line-height: 26px;
	margin: 0 0 0 20px;
	padding: 0px;
}


.content{
	ul, ol{
		font-family: $font2;
		font-size: 16px;
		line-height: 26px;
		font-weight: 400;
		margin-left: 18px;
	}

	ol {
	  list-style: none;
	  counter-reset: my-awesome-counter;
	  margin: 0;
	  padding: 0;
	}
	ol li {
	  position: relative;
	  counter-increment: my-awesome-counter;
	  margin-bottom: 0.5rem;
	  padding: 20px 0 0 70px;
	  min-height: 60px;
	}
	ol li::before {
		position: absolute;
	  content: counter(my-awesome-counter);
	  font-weight: bold;
	  font-size: 3rem;
	  margin-right: 0.5rem;
	  line-height: 1;
	  border-radius: 50%;
	  background-color: #ffaa00;
	  color: #fff;
    width: 60px;
    height: 60px;
    display: inline-block;
    text-align: center;
    line-height: 56px;
    top: 0;
    left: 0;
	}
}

.btn {
	margin: 0px;
	display: inline-block;
	font-family: inherit;
	font-size: 18px;
	font-weight: 700;
	padding: 18px 40px;
	border-radius: 100px;
	background-color: $color1;
	box-shadow: 0px 0px 30px -7px rgba(0,0,0,0.75);
	transition: all 0.3s;
	text-transform: uppercase;
	border: none;
	color: #fff;
	cursor: pointer;
	// &:hover, &:focus {
	// 	filter: brightness(80%);
	// }
	@media (max-width: $mobile) {
		font-size: 16px;
		padding: 14px 32px;
	}
}



.margin-none{
	margin: 0px !important;
}


.related-holder {
	margin-left: -10px;
	margin-right: -10px;
	.col-6 {
		padding: 20px 10px;
	}
}
.related-button {
	position: relative;
	transition: all 0.3s;
	border: 1px solid #000;
  border-left: 5px solid #000;
	&:hover, &:focus {
		border-left: 8px solid #000;
		filter: brightness(85%);
		a {
			padding-left: 17px;
		}
	}
  a {
  	display: block;
  	padding: $spacing;
  	transition: all 0.3s;
  }
  h4 {
  	font-size: 18px;
  	margin: 0 0 $spacing/2 0;
  }
  p {
  	font-size: 14px;
  	line-height: 24px;
  	margin: 0 0 $spacing/2 0;
  }
  span {
  	float: right;
  	padding: 8px 12px;
    border-radius: 0;
    font-size: 14px;
    border: none;
 		color: #fff;
 		display: inline-block;
    border-radius: 100px;
  }
}
.recycle.related-button {
  border-color: $color3;
  color: $color3;
  h4, p {
  	color: $color3;
  }
  span {
 		background-color: $color3;
  }
}
.classic.related-button {
  border-color: $color1;
  color: $color1;
  h4, p {
  	color: $color1;
  }
  span {
 		background-color: $color1;
  }
}
.express.related-button {
  border-color: #009BDE;
  color: #009BDE;
  h4, p {
  	color: #009BDE;
  }
  span {
 		background-color: #009BDE;
  }
}


