.contact-image{
	height: 600px;
	width: 100%;
	overflow: hidden;
	position: relative;
	@media (max-width: 910px) {
    height: 100%;
  }
	img{
		width: 100%;
		position: absolute;
		top: 50%;
		transform: translate(0%, -50%);
		@media (max-width: 910px) {
	    position: relative;
	   	top: 0;
	   	transform: none;
	  }
	}
}
.contact{
	padding-top: $spacing*2;
	ul{
		margin: 25px 0 0 0;
		list-style-type: none;
		padding: 0px;
		font-family: $font2;
		font-size: 16px;
		line-height: 26px;
		a{
			color: inherit;
			font-weight: 700;
		}
	}
  .list{
    @media (max-width: $mobile) {
      &:first-child{
        margin-bottom: 0px;
      }
      &:nth-child(2){
        margin-top: 0px;
      }
    }
    input{
      position: absolute;
      top: 50%;
      transform: translate(0%, -50%);
      left: -20px;
      zoom: 1.5;
      width: 20px;
      
      @media (max-width: $mobile) {
        zoom: 1;
        left: -25px;
      }      
    }
    label{
      position: relative;
      margin: 10px 0px;
      display: block;
      margin-left: 28px;
      width: calc(100% - 28px);      

      a {
        font-family: inherit;
        color: inherit;
        text-decoration: underline;
      }
    }
  }	

}




// Gravity Forms
.gform_wrapper {
  .gform_title {
    display: none;
  }
  #gform_fields_1 {
    margin: 0 -$spacing !important;
  }
  ul.gform_fields {
    display: flex !important;
    flex-wrap: wrap !important;
    #field_1_8 {
      flex-basis: 100% !important
    }
    .gfield {
      flex-basis: 50% !important;
      padding: $spacing/2 $spacing !important;
      input {
        width: 100% !important;
      }
      input[type="checkbox"] {
        width: auto !important;
      }
      @media (max-width: $mobile) {
        flex-basis: 100% !important;
      }
    }
  }
}

.contact-form {
  .gform_wrapper {
    .gform_title {
      display: none;
    }
    #gform_fields_1 {
      margin: 0 -$spacing !important;
    }
    ul.gform_fields {
      display: flex !important;
      flex-wrap: wrap !important;
      #field_1_8 {
        flex-basis: 100% !important
      }
      .gfield {
        flex-basis: 100% !important;
        padding: $spacing/2 $spacing !important;
        input {
          width: 100% !important;
        }
        input[type="checkbox"] {
          width: auto !important;
        }
        @media (max-width: $mobile) {
          flex-basis: 100% !important;
        }
      }
    }
  }
}

