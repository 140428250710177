.category{
	.clothing-item{
		position: relative;

		&.recycled-range {
			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				width: 100px;
				height: 35px;
				background-image: url('../images/repreve.png');
				background-size: contain;
				background-repeat: no-repeat;
			}
		}
		img {
			height: 360px;
			width: auto;
		}

		.front-img{
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, 0%);
			transition: all 0.3s;
		}
		.back-img{
			opacity: 0;
			transition: all 0.3s;
		}
		&:hover{
			.back-img{
				opacity: 1;
			}
			.front-img{
				opacity: 0;
			}
		}
	}
}