.product-intro{
  padding: $spacing*2 0px;

  @media (max-width: $mobile) {
    padding: 0;
  }    
}

.text-section{
  padding: $spacing*2 0px;
  background-color: #f9f9f9;
}

.product-contact{
  padding: $spacing*2 0px;
  background-color: #EEEEEE;
  // box-shadow: inset 0px 0px 300px -93px rgba(0,0,0,0.75);
  label{
    text-transform: uppercase;
  }
  .col-6, .col-12{
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .list{
    @media (max-width: $mobile) {
      &:first-child{
        margin-bottom: 0px;
      }
      &:nth-child(2){
        margin-top: 0px;
      }
    }
    input{
      position: absolute;
      top: 50%;
      transform: translate(0%, -50%);
      left: -20px;
      zoom: 1.5;
      width: 20px;

      @media (max-width: $mobile) {
        zoom: 1;
        left: -25px;
      }
    }
    label{
      position: relative;
      margin: 10px 0px;
      display: block;
      margin-left: 28px;
      width: calc(100% - 28px);

      a {
        font-family: inherit;
        color: inherit;
        text-decoration: underline;
      }
    }
  }
  textarea{
    height: 150px;
  }
}

.product-table{
  padding: $spacing*2 0px;
  background-color: #F7F7F7;

  thead{
    background-color: $color3;
  }
  tbody{
    font-family: $font2;
  }
  table {
    font-size: 16px;
    border: none;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  tbody tr:nth-child(even){
    background-color: #EDEDED;
  }
  tbody tr:nth-child(odd){
    background-color: #fff;
  }

  table tr {
    border: none;
    padding: 10px;
  }

  table th,
  table td {
    padding: 15px;
    text-align: left;
  }

  table th {
    font-weight: 400;
    text-transform: uppercase;
  }
}



.size-holder{
  margin-bottom: 60px;
  thead{
      background-color: $color3;
    }
    tbody{
      font-family: $font2;
    }
    table {
      font-size: 16px;
      border: none;
      border-collapse: collapse;
      margin: 0;
      padding: 0;
      width: 100%;
    }

    tbody tr:nth-child(even){
      background-color: #EDEDED;
    }
    tbody tr:nth-child(odd){
      background-color: #fff;
    }

    table tr {
      border: none;
      padding: 10px;
    }

    table th,
    table td {
      padding: 15px;
      text-align: left;
    }

    table th {
      font-weight: 400;
      text-transform: uppercase;
    }
}

.mobile-table {
  overflow-x:auto;
}

.tabs {
  padding-bottom: 40px;
  .tab {
    overflow: hidden;
    border: none;
    background-color: #f1f1f1;
  }

  /* Style the buttons inside the tab */
  .tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
    border-radius: 0;
    color: #000;
  }

  /* Change background color of buttons on hover */
  .tab button:hover {
    background-color: #ddd;
  }

  /* Create an active/current tablink class */
  .tab button.active {
    background-color: #ccc;
  }

  /* Style the tab content */
  .tabcontent {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
  }
}

.desktop-only {
  display: block;

  @media (max-width: $mobile) {
    display: none;
  }  
}

.mobile-only {
  display: none;

  @media (max-width: $mobile) {
    display: block;
  }  
}



