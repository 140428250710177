// Font
$font1: 'Montserrat', sans-serif;
$font2: 'Montserrat', sans-serif;
// $font2: 'Source Sans Pro', sans-serif;

// Colours
$color1: #e6007d;
$color2: #F9B700;
$color3: #98BA6B;
$color4: #6DC8F2;
$offwhite: #F7F7F7;


// Spacing
$col-spacing: 20px;
$spacing: 20px;

// Screen Sizes
$mobile: 768px;
$small-screen: 992px;
$large-screen: 1200px;

// Cookie
$cookie-background: #000;
$cookie-font-color: #fff;
$cookie-button: #2BA3DB;