.form-group {
	width: 100%;
	margin: 0px 0px $spacing 0px;
	label, input, textarea {
		display: block;
		width: 100%;
		font-size: inherit;
		font-family: inherit;
	}
	label{
		margin: $spacing 0px 5px 0px;
	}
	input{
		padding: 10px;
		margin: 0 0 5px 0;
		border: 2px solid #eee;
	}
	textarea{
		width: 100%;
		padding: 10px;
		resize: none;
		border: 2px solid #eee;
	}
	input[type=submit], button{
		width: auto;
		font-size: inherit;
		font-family: inherit;
		padding: 15px 20px;
		text-transform: uppercase;
		background-color: $color1;
		border: none;
		color: #fff;
		cursor: pointer;
		-webkit-appearance: none;
	}
}

.parsley-error{
	background-color: #F2DEDE !important;
}
.parsley-errors-list, #lblMessage{
	list-style: none;
	font-size: 16px !important;
	font-family: $font2;
	padding: 0px !important;
	color: #A9555B;
	margin: 0px 0px 10px 0px !important;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #757575;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #757575;
  opacity: 1;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #757575;
  opacity: 1;
}
:-moz-placeholder { /* Firefox 18- */
  color: #757575;
  opacity: 1;
}
select{
	color: #757575;
}

.alert{
	margin: 10px 0px;
	padding: 10px;
	&-success{
		background-color: #DFF0D8;
		color: #5B763D;
	}
	&-error{
		background-color: #F2DEDE;
		color: #A9555B;
	}
}
input[type=submit], button{
		width: auto;
		font-size: inherit;
		font-family: inherit;
		padding: 15px 20px;
		text-transform: uppercase;
		background-color: $color1;
		border: none;
		color: #fff;
		cursor: pointer;
		-webkit-appearance: none;
		border-radius: 100px;
	}

